$(document).ready(function(){
    // Get the button
    let mybutton = document.getElementById("backTopButton");
       
       // When the user scrolls down 20px from the top of the document, show the button
       window.onscroll = function() {scrollFunction()};
       
       function scrollFunction() {
         if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
           mybutton.style.display = "block";
         } else {
           mybutton.style.display = "none";
         }
       }
       
       width = $( window ).width();
        setTimeout(() => {
            // When the user clicks on the button, scroll to the top of the document
       // conditional  to hide responsive Menu name or button on >900px screens
       if(width < 992 && width > 768){
     //   alert('assd');
          $('[data-widget="pushmenu"]').PushMenu("collapse");
       }

       if(width <= 992){
        $('.nav-sidebar .nav-item.nav-item-n').click(function(){
          $('#sidebar-overlay').click();
        })
        }

       }, 800);
   });
   function topFunction() {
         document.body.scrollTop = 0;
         document.documentElement.scrollTop = 0;
       }